import { useState } from 'react';
import { useKeyPress } from 'hooks/useKeyPress';
import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled';
import { TextInput, Checkbox, BaseButton, Button, Text } from 'components';
import { Trans, t } from '@lingui/macro';
import { EnvelopeSimple, Lock, Eye, EyeSlash } from '@phosphor-icons/react';
import { CenterContainer } from './CenterContainer';
import { DOMAIN } from 'config';
import { colors } from 'styles/theme/colors';

type LoginFormProps = {
  onSubmit: (user: string, password: string, remember: boolean) => void;
  isLoading?: boolean;
};

export const LoginForm = ({ onSubmit, isLoading = false }: LoginFormProps) => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);

  useKeyPress('Enter', () => {
    onSubmit(user, password, remember);
  });

  return (
    <CenterContainer
      welcomeText={
        <Text variant="bodyMd" fontFamily="Gazpacho">
          <Trans>... Welcome back</Trans>
        </Text>
      }
    >
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(user, password, remember);
        }}
      >
        <Form>
          <Header>
            <Text variant="headlineXs" textVariant="semiBold">
              <Trans>Log in to your account</Trans>
            </Text>
          </Header>
          <TextInput
            placeholder={t`Email`}
            InputProps={{ startAdornment: <EnvelopeSimple /> }}
            variant="outlined"
            value={user}
            onChange={e => {
              setUser(e.target.value);
            }}
            disabled={isLoading}
            name="user"
            autoComplete="username"
          />
          <TextInput
            placeholder={t`Password`}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              startAdornment: <Lock />,
              endAdornment: (
                <BaseButton
                  data-testid="eye"
                  onClick={() => setShowPassword(!showPassword)}
                  type="button"
                >
                  {showPassword ? <Eye /> : <EyeSlash />}
                </BaseButton>
              ),
            }}
            variant="outlined"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            disabled={isLoading}
            name="password"
            autoComplete="current-password"
          />
          <RememberForgot>
            <Remember>
              <Checkbox
                sx={{
                  width: '18px',
                  height: '18px',
                  '&.MuiCheckbox-root': {
                    padding: 0,
                    marginRight: 2,
                  },
                }}
                checked={remember}
                label={t`Remember me`}
                onChange={() => setRemember(!remember)}
                checkedIcon={<CheckedIcon />}
              />
            </Remember>
            <a href={`${DOMAIN}/forgot`}>
              <Forgot>
                <Trans>Forgot password?</Trans>
              </Forgot>
            </a>
          </RememberForgot>
        </Form>
        <SignIn center={isLoading}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button variant="primary" color="primary" type="submit">
              <Trans>Log in</Trans>
            </Button>
          )}
        </SignIn>
      </form>
    </CenterContainer>
  );
};

const Header = styled.h1`
  margin-bottom: 16px;
  color: ${p => p.theme.palette.text.primary};
  font-weight: 600;
  font-size: 18px;
`;

const Form = styled.div`
  margin: 16px 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  svg {
    fill: ${p => p.theme.palette.fg.quinary};
  }
`;

const RememberForgot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const Remember = styled.span`
  label {
    color: ${p => p.theme.palette.text.secondary};
    font-size: 12px;
    font-weight: 400;
  }
  .MuiCheckbox-root {
    padding: 0;
    padding-right: 4px;
  }
`;

const Forgot = styled.span`
  font-size: 12px;
  color: ${p => p.theme.palette.text.link};
  font-weight: 600;
`;

const SignIn = styled.div<{ center: boolean }>`
  margin: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${p => (p.center ? 'align-items: center' : '')};
`;

const CheckedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 50 50">
      <rect
        x="5"
        y="5"
        width="40"
        height="40"
        fill={colors.brand[100]}
        stroke="none"
        rx="5"
        ry="5"
      />
      <path
        d="M15 28 L22 35 L36 15"
        fill="none"
        stroke={colors.brand[500]}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
