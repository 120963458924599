import { i18n } from '@lingui/core';
import { messages as en } from 'locales/en';
import { messages as da } from 'locales/da';

const locales = { en, da };

type Locale = keyof typeof locales;

export const setLocale = (locale: Locale = 'en') => {
  i18n.loadAndActivate({ locale, messages: locales[locale] });
};
