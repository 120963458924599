import { useMemo, memo, useState, useCallback, useEffect } from 'react';
import { Skeleton, styled } from '@mui/material';
import { ErrorOverlay } from './ErrorOverlay';
import type { SxProps } from '@mui/material';
import type { ImageProps } from './types';
import { ClipboardText } from '@phosphor-icons/react';

const ImageComponent = ({
  sx: sxProp,
  src,
  width = '100%',
  height = 'auto',
  aspectRatio,
  alt,
  loading: loadingProp = 'lazy',
  isLoading: isLoadingProp,
  fit = 'contain',
  renditions,
  sizes,
  role,
  showFallback,
}: ImageProps) => {
  const sx: SxProps = useMemo(
    () => ({
      ...sxProp,
      width,
      height: aspectRatio ? 'auto' : height,
      aspectRatio,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [sxProp, width, height]
  );

  const [imgError, setImgError] = useState<Error | null>(null);

  const srcSet = useMemo(() => {
    return Object.entries(renditions || {})
      .map(rendition => {
        const keyAsWidth = parseInt(rendition[0]);
        const renditionSrc = rendition[1];
        if (!Number.isInteger(keyAsWidth)) return null;
        return `${renditionSrc} ${keyAsWidth}w`;
      })
      .filter(Boolean)
      .join(',');
  }, [renditions]);

  const handleError = useCallback(() => {
    setImgError(new Error(`Failed to load image "${src}"`));
  }, [src]);

  useEffect(() => {
    setImgError(null);
  }, [src]);

  const isLoading = isLoadingProp;
  const isSrc = Boolean(src) || Boolean(srcSet);

  return (
    <>
      {imgError && !showFallback && <ErrorOverlay width={width} height={height} error={imgError} />}
      {((showFallback && !isSrc) || !isSrc) && (
        <ClipboardTextIcon
          size={32}
          width={width}
          height={aspectRatio ? 'auto' : height}
          style={{ minWidth: width }}
        />
      )}

      {isLoading && !imgError ? (
        <Skeleton
          data-testid="skeleton-loader"
          variant="rectangular"
          width={width}
          height={aspectRatio ? 'auto' : height}
          sx={{
            aspectRatio,
          }}
        />
      ) : null}
      {isSrc && (
        <Img
          data-testid="image-tag"
          loading={loadingProp}
          src={src}
          alt={alt}
          onError={handleError}
          srcSet={srcSet}
          sizes={sizes}
          role={role}
          sx={{
            objectFit: fit,
            visibility: isLoading || imgError ? 'hidden' : 'visible',
            ...sx,
          }}
        />
      )}
    </>
  );
};

const Img = styled('img')({
  width: 'auto',
  height: 'auto',
  position: 'absolute',
});

export const Image = memo(ImageComponent, (prevProps, nextProps) => {
  return (
    prevProps.src === nextProps.src &&
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height
  );
});

const ClipboardTextIcon = styled(ClipboardText)`
  fill: ${p => p.theme.palette.fg.secondary};
`;
