import { Box, Button, Modal as MuiModal, styled } from '@mui/material';
import { Minus, X } from '@phosphor-icons/react';
import { useStoreActions, useStoreState } from 'stores/global';
import { useMemo } from 'react';
import { ModalProps, ButtonType } from 'features/modalManager/types';
import { t } from '@lingui/macro';
import { useTheme } from '@emotion/react';
import { Checkbox } from 'components';
import useLocalStorageState from 'use-local-storage-state';

export const Modal = (props: ModalProps) => {
  const {
    modalSx,
    windowTitle,
    minimize = true,
    id,
    trigger,
    onClose,
    onMinimize,
    onOpen,
    onBackdropClick,
    buttons,
    resolve,
    body,
    children,
    showAgainCheckbox,
  } = props;

  const { activeId } = useStoreState(state => state.modalManager);
  const { add, remove, setActiveId, setModalListVisibility } = useStoreActions(
    actions => actions.modalManager
  );

  const [dontShowAgain, setShowAgain] = useLocalStorageState(`${showAgainCheckbox}-showAgain`, {
    defaultValue: false,
  });

  const theme = useTheme();

  const styles = useMemo(() => {
    return {
      zIndex: activeId === id ? 1300 : -1,
    };
  }, [activeId]);

  const handleOpen = () => {
    setActiveId(id);
    add({ ...props });
    onOpen && onOpen();
  };

  const handleClose = (reason: string) => {
    setActiveId('');
    remove(id);
    onClose && onClose();
    resolve && resolve(reason);

    if (reason === 'backdropClick') {
      onBackdropClick && onBackdropClick();
    }
  };

  const handleMinimize = () => {
    setActiveId('');
    setModalListVisibility(true);
    onMinimize && onMinimize();
  };

  const onButton = (button: ButtonType) => {
    if (button.onClick) {
      button.onClick();
    }
    handleClose(button.id);
  };

  return (
    <>
      <div>
        {trigger?.Component ? (
          <CustomButton onClick={handleOpen}>{trigger?.Component}</CustomButton>
        ) : (
          <Button
            onClick={handleOpen}
            variant={trigger?.variant ? trigger.variant : 'secondary'}
            disableRipple
          >
            {trigger?.label}
          </Button>
        )}
      </div>
      <MuiModal
        id={id}
        data-testid="modal-component"
        open={activeId === id}
        keepMounted={minimize}
        sx={styles}
        onClose={(_, reason: string) => handleClose(reason)}
        slotProps={{
          backdrop: { sx: { backgroundColor: 'transparent', backdropFilter: 'blur(2px)' } },
        }}
      >
        <ModalWindow sx={modalSx?.window}>
          <WindowBar sx={modalSx?.windowBar}>
            <WindowTitle>{windowTitle}</WindowTitle>
            <WindowControls>
              {minimize && (
                <Circle data-testid="minimize-modal" onClick={handleMinimize} title={t`Minimize`}>
                  <Minus size={24} color={theme.palette.border.primary} />
                </Circle>
              )}
              <Circle
                data-testid="close-modal"
                onClick={() => handleClose('Close button')}
                title={t`Close`}
              >
                <X size={24} color={theme.palette.border.primary} />
              </Circle>
            </WindowControls>
          </WindowBar>
          {Array.isArray(body) ? (
            body.map(text => <Body key={text}>{text}</Body>)
          ) : (
            <Body>{children ? children : body}</Body>
          )}
          <Footer>
            {showAgainCheckbox ? (
              <Checkbox
                label={t`Don't show again`}
                checked={dontShowAgain}
                onChange={() => setShowAgain(!dontShowAgain)}
              />
            ) : null}
            {buttons?.length ? (
              <Buttons>
                {buttons.map(button => (
                  <Button
                    key={button.label}
                    variant={button.variant}
                    onClick={() => onButton(button)}
                  >
                    {button.label}
                  </Button>
                ))}
              </Buttons>
            ) : null}
          </Footer>
        </ModalWindow>
      </MuiModal>
    </>
  );
};

const ModalWindow = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  background: theme.palette.surface.light,
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  border: '1px solid black',
  borderColor: theme.palette.border.primary,
  overflow: 'hidden',
  boxShadow: '0px 0px 4px 0px rgba(34, 34, 34, 0.08)',
  ':focus-visible': {
    outline: 'none',
  },
}));

const WindowBar = styled('div')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  display: 'flex',
  justifyContent: 'space-between',
  margin: theme.spacing(6),
  marginBottom: theme.spacing(2),
}));

const Body = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(6),
  marginReft: theme.spacing(6),
  marginBottom: theme.spacing(2),
}));

const Buttons = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(4),
}));

const Footer = styled('div')(({ theme }) => ({
  margin: theme.spacing(6),
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
}));

const WindowTitle = styled('h3')(({ theme }) => ({
  margin: 0,
  fontSize: theme.typography.headerPopUp.fontSize,
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

const WindowControls = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

const Circle = styled(Box)(({ theme }) => ({
  width: 24,
  height: 24,
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}));

const CustomButton = styled('button')({
  margin: 0,
  padding: 0,
  border: 0,
  background: 'transparent',
  display: 'flex',
  cursor: 'pointer',
});
