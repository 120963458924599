import { colors } from 'styles/theme/colors';
import { format } from 'date-fns';
import { styledTheme } from 'styles/theme/theme';

export const handleResponse = async (res: Response) => {
  const data = await res.json();

  return res.ok ? data : Promise.reject(data);
};

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getLinkedColor = (linkedTo: string) => {
  switch (linkedTo) {
    case 'Style':
      return {
        color: styledTheme.palette.text.link,
        deleteIconColor: styledTheme.palette.utility.marineBlue[400],
      };
    case 'Item':
      return {
        color: styledTheme.palette.utility.lavender[800],
        deleteIconColor: styledTheme.palette.utility.lavender[600],
      };
    case 'Sampling':
      return {
        color: styledTheme.palette.text.brandSecondary,
        deleteIconColor: styledTheme.palette.border.brand,
      };
    case 'Certificate':
      return {
        color: styledTheme.palette.text.warning,
        deleteIconColor: styledTheme.palette.border.warning,
      };
  }
};

export const getPriorityColor = (priority: string) => {
  switch (priority) {
    case 'Low':
      return colors.marineBlue[200];
    case 'Medium':
      return colors.marineBlue[300];
    case 'High':
      return colors.marineBlue[400];
    default:
      return colors.marineBlue[200];
  }
};
export const getStateColor = (state: string) => {
  switch (state) {
    case 'To Do':
      return colors.grey[600];
    case 'In Progress':
      return colors.warning[400];
    case 'Stuck':
      return colors.error[500];
    case 'Done':
      return colors.success[500];
  }
};
export const formatDateTime = (dateString: string | undefined, dateFormat: string) => {
  return dateString ? format(Date.parse(dateString), dateFormat) : '';
};

export const stringParse = (text: string, params?: string[]) => {
  // replace all {0}, {1}, {2} with the params
  if (!params) {
    return text;
  }
  return text.replace(/{(\d+)}/g, (match, number) => {
    return typeof params[number] !== 'undefined' ? params[number] : match;
  });
};

export const getKeyByValue = (obj: { [key: number]: string }, value: string) => {
  return Number(Object.keys(obj).find(key => obj[Number(key)] === value));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValueInArray(arr: any[], value: any): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function deepEqual(a: any, b: any): boolean {
    if (a === b) return true; // Same reference or primitive value
    if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) return false;

    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) return false;

    for (const key of aKeys) {
      if (!Object.prototype.hasOwnProperty.call(b, key) || !deepEqual(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }

  return arr.some(item => {
    if (typeof item === 'object' && item !== null) {
      if (Array.isArray(item)) {
        return Array.isArray(value) ? deepEqual(item, value) : false;
      }
      return deepEqual(item, value);
    }
    return item === value;
  });
}
