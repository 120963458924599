import { AuthProvider } from 'providers';
import { Navigate } from 'react-router-dom';
import { App } from 'app';
import { AdminRoutes } from './admin';
import { AnalyticsRoutes } from './analytics';
import { ComplianceRoutes } from './compliance';
import { DashboardRoutes } from './dashboard';
import { ItemRoutes } from './item';
import { MarketingRoutes } from './marketing';
import { OrdersRoutes } from './orders';
import { ReportsRoutes } from './reports';
import { StyleRoutes } from './style';
import { ExampleRoutes } from './example';
import { ErrorBoundary } from 'components';
import { TaskboardRoutes } from './taskboard';

export const AppRoutes = [
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ErrorBoundary>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/taskboard?sort=deadline&order=ASC" replace />,
      },
      DashboardRoutes,
      StyleRoutes,
      ItemRoutes,
      OrdersRoutes,
      ReportsRoutes,
      AnalyticsRoutes,
      MarketingRoutes,
      ComplianceRoutes,
      AdminRoutes,
      ExampleRoutes,
      TaskboardRoutes,
    ],
  },
];
