import { Global, css, useTheme } from '@emotion/react';
import { colors } from './theme/colors';

export const GlobalStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        main,
        body {
          overflow: hidden;
          background-color: #ffffff;
          font-size: ${theme.typography.mainBodyText.fontSize};
          line-height: ${theme.typography.mainBodyText.lineHeight};
          font-weight: ${theme.typography.mainBodyText.fontWeight};
          color: ${theme.typography.mainBodyText.color};
        }
        a {
          color: ${colors.black};
          text-decoration: none;
          &:hover {
            text-decoration: underlined;
          }
        }
        ul {
          margin: 0;
          padding-left: 0;
        }
        /* Hide Sentry marketing in Dialog component */
        .powered-by {
          display: none;
        }
        /* MUI overrides */
        .MuiAutocomplete-listbox {
          color: ${theme.palette.text.secondary};
        }
        ul.MuiAutocomplete-listbox li.MuiAutocomplete-option.Mui-focused {
          background-color: ${theme.palette.bg.brandSubtle};
        }
        .MuiButtonBase-root {
          &.MuiButton-primary {
            background-color: ${theme.palette.bg.brandSolid};
            border-color: ${theme.palette.bg.brandSolid};
            :hover {
              background-color: ${theme.palette.bg.brandSolidHover};
              border-color: ${theme.palette.bg.brandSolidHover};
            }
          }
        }
      `}
    />
  );
};
